@import "../../../styles/layout/color.scss";

.report-container {
  max-width: 96.4%;
  margin-left: 1.5rem !important;
  box-shadow: 1px 1px #dcdcdc !important;
  background-color: white;
  border-radius: 5px !important;
  border: solid $grey 1px !important;
  .dynamicFormWrapper .customDatepicker {
    width: 100%;
    height: 41px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding-left: 10px;
    outline: none;
    margin-top: 5px;
  }
  .dynamicFormWrapper .selectInput {
    height: 41px !important;
    width: 100%;
    color: $black;
    margin-top: 5px;
  }
  &_ReportTxt {
    color: $DuskBlue;
    font-size: 16px;
  }
  .report-subtitle {
    font: normal normal 600 12px/21px Open Sans;
    letter-spacing: 0px;
    color: $black;
    opacity: 1;
    padding-bottom: 5px;
  }
  .report-dropdown {
    // border: 1px solid #BCBCBC;
    // border-radius: 3px;
    // padding: 0.2rem;
    // height:2.2rem;
    // font: normal normal normal 14px/20px Open Sans;
    // letter-spacing: 0px;
    // color: #A7A7A7;
    // opacity: 1;
    padding: 0px;
    border: 1px solid $gray;
    border-radius: 5px;
  }
  .form-select:focus {
    border-color: $gray;
    outline: 0;
    box-shadow: none;
  }
  .avreport-datepicker {
    background: $white;
    padding: 2px;
    height: 38px !important;
    border-radius: 5px;
  }
  .datepicker {
    border-radius: 5px;
  }
}
