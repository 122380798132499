@import "../../../../../styles/layout/color.scss";

.placeholder {
    height: 120px !important;
    padding-top: 50px !important;
    width: 450px !important;
}

.dropWrapper {
    height: 80px;
    border: 2px dashed $borderGrey;
    color: $navy-blue;
    font-weight: 600;
    border-radius: 7px;
}

.uploadContent {
    word-break: break-word;
    max-width: 300px;
}

.customButtonGreenInverted:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
}

.customButtonGreenInverted {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $white;
    font-weight: 500;
    width: 160px;
    min-height: 40px;
}

.customButtonGreenInverted:hover {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $white;
    font-weight: 500;
    width: 160px;
    min-height: 40px;
}

.customButtonGreenInverted {
    border: 1px solid $Lemongreen;
}

.customButtonGreen:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
}

.customButtonGreen {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $Lemongreen;
    font-weight: 600;
    width: 160px;
    min-height: 40px;
}

.customButtonGreen:hover {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $Lemongreen;
    font-weight: 600;
    width: 160px;
    min-height: 40px;
}

.customButtonGreen {
    border: 1px solid $Lemongreen;
}