@import '../../../styles/layout/common.scss';
.manage-container{
    width: 95%;
}

.custom-table {

    table {
        thead {
            th:nth-child(3) {
              padding-left: 15px !important;
              padding-right: 15px !important;
              min-width: max-content !important;
            }
            th:nth-child(4) {
              padding-left: 15px !important;
              padding-right: 15px !important;
              min-width: max-content !important;
            }
            th:nth-child(5) {
              padding-left: 15px !important;
              padding-right: 15px !important;
              min-width: max-content !important;
            }
          }
      .status {
        min-width: max-content !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .totalCases {
        min-width: max-content !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .userName {
        min-width: max-content !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }