@import "../../../styles/layout/color.scss";

.HoldPayment {
  &_tableAlignment {
    &_checkbox {
      height: 19px !important;
      width: 19px !important;
    }

    .custom-table {
      margin-top: 0 !important;

      table {
        thead {
          height: 3.45rem;

          th {
            min-width: 10rem;
          }

          th:first-child {
            min-width: max-content !important;
          }
        }

        td {
          min-width: max-content;
        }
      }
    }

    .custom-table table {
      overflow: hidden;
      border-radius: 0px 10px 0rem 0rem !important;
      margin-bottom: 0px;
    }

    .id {
      min-width: max-content !important;
    }

    .branch,
    .payTo {
      min-width: 180px !important;
    }

    .journalNo {
      min-width: 240px !important;
    }

    .Cheque_DD_Date,
    .transactionDate,
    .disAmt,
    .productDesc,
    .txnIMBSName,
    .beneNameScore,
    .Bene_Bank_Acct {
      min-width: 204px !important;
    }
  }

  &_holdPaymentTxt {
    color: $navy-blue;
  }
}

.regular-payment-wrapper-row {
  height: 100%;

  .Payment_DisbursmentCaseWrapper {
    height: 100%;
    background: $white;
  }

  .RegularPayment_max-widthTable {
    border: 1px solid $grey;
    border-left: 0px;
    background: $white;
    height: 100%;
  }
}

.Payment {
  &_DisbursmentCaseWrapper {
    position: relative;
    min-height: 39rem;
    border: 1px solid $grey;

    &_Table-Head {
      min-height: 54px;
      display: flex !important;
      border-radius: 10px 0rem 0rem 0rem;
      background-color: $navy-blue;
      padding: 0.5rem 8%;
      width: 100%;

      p {
        color: $white !important;
        position: relative;
        top: 0.6rem;
        font-weight: 500;
      }
    }
  }

  &_DisbursmentCaseWrapper {
    border-top-left-radius: 10px;

    &_bottomButtons {
      position: absolute;
      bottom: 1rem;

      button {
        background-color: $Lemongreen !important;
        color: $black;
        border: 0;
        padding: 6px 2rem;
      }

      &_outlineBtn {
        button {
          background-color: $white !important;
          border: 1px solid $Lemongreen;
          padding: 6px 2.5rem;
        }
      }
    }
  }
}

.manage-inputs {
  padding: 0px;
  border: 1px solid $grey;
  border-radius: 5px;
  opacity: 1;
  min-width: 100%;
  min-height: 40px;
}

.dynamicFormWrapper .selectInput {
  height: 48px;
  width: 100%;
  color: $black !important;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: 1px solid $placeHolder;
  border-width: thin;
  padding: 1px 1px 1px 5px;
  box-shadow: none;
  outline: none;
}

.dynamicFormWrapper .input {
  box-shadow: none;
  outline: none;
}

.dynamicFormWrapper .customButtonGreen {
  border: 1px solid $Lemongreen;
  color: $black;
  background: $Lemongreen !important;
  // box-shadow: 0 !important;
  font-weight: 600;
  width: 160px;
  min-height: 40px;
}

.dynamicFormWrapper .GreenButton {
  border: 1px solid $Lemongreen;
  color: $black;
  background: $dark-green;
  font-weight: 500;
  width: 160px;
  min-height: 40px;
}

.disabled {
  opacity: 0.6;
  /* Adjust the opacity value as per your design preference */
  pointer-events: none;
  /* Prevent pointer events on the button */
}