@import "../styles/layout/color.scss";
.grid-container {
  display: grid;
  grid-template-columns: 280px auto;
}
.grid-container-hide {
    display: grid;
    grid-template-columns: 0px auto;
}
.HamburgerIcon{
    background: $white;
    border: 2px solid $grey;
    border-radius: 6px;
    position: absolute;
    top: 24px;
    transform: rotateZ(90deg);
    left: -28px;
}
.left-column {
  height: calc(100vh - 80px);
}

.right-column {
  height: auto;
  height: calc(100vh - 80px);
  overflow: auto;
  position: relative;
  background: $light-Blue !important;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
    background-color: $GhostWhite;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: $GhostWhite;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $navy-blue;
  }
}
