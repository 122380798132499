@import '../../../../../styles/layout/color.scss';

.mandate-hdfc-table{
  
  .bank,.companyCode,.download{
    font-weight: bold;
    color: $tableBoldFont;
  }
  .srno{
    min-width: 70px;
    padding-left: 15px;
    font: normal normal bold 14px/19px Open Sans;
    color: $tableBoldFont;
  }
  .bank{
    min-width: 70px;
    padding-left: 10px;
    font: normal normal bold 14px/19px Open Sans;
    color: $tableBoldFont;
  }
  .companyCode{
    min-width: 140px;
    font: normal normal 600 14px/19px Open Sans;
    color: $tableBoldFont;
  }
  .mandatetype{
    min-width: 140px;
    font: normal normal 600 14px/19px Open Sans;
    color: $tableBoldFont;
  }
  .fileName{
    max-width:350px !important;
    word-wrap: break-word;
    font: normal normal normal 14px/19px Open Sans;
    color: $black;
  }
  .sftpFolder{
    min-width: 130px;
    font: normal normal normal 14px/19px Open Sans;
  }

}
.hdfc-input{
  height: 32px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 3px;
}
.hdfc-dropdown{
    border: 1px solid $dark-gray;
    border-radius: 3px;
    padding: 0.2rem;
    min-width: 157px;
    height:2rem;
    font: normal normal 600 10px/14px Open Sans;
}
.form-select:focus{
  border-color: $gray;
  outline: 0;
  box-shadow:none
}

.labelPresentation{
  width: 500px;
}

.btn .transaparentBtn{
  color:$tableBoldFont;
  font-weight: bold;
}
.hdfcReportHeader{
  .exportBtn{
    height: 38px !important;
  }
  .selectInput{
    height: 38px !important;
    min-width: 175px !important;
  }
}
