@import "../../../../styles/layout/color.scss";
.createmanage{
    .heading{
        font: normal normal 600 18px/24px Open Sans;
        letter-spacing: 0px;
        color:$navy-blue;
        opacity: 1;
    }
    .sub-headings{
        font: normal normal 600 14px/21px Open Sans;
        letter-spacing: 0px;
        padding-left: 3px;
        color: $black;
        opacity: 1;  
    }
    .manage-inputs{
        padding: 0px;
        
        border: 1px solid $gray;
        border-radius: 3px;       
    }
    .manage-date{
        padding: 2px;
        height: 38px !important;
        border-radius: 3px;
        // border: 1px solid #DCDCDC;
    }
   
    .datepicker{
        max-width: 400px;
        border-radius: 3px;
    }
    .red-star { 
        color: $red; 
    } 
    .required:after {
         content: " *";
          color: $red; 
    }
    .errorMessage { 
        display: flex; 
        justify-content: flex-start; 
        color: $red; 
        margin-top: 8px;
    } 
    .formSelect{
        min-width: 400px;
        min-height: 38px;
    }
    .cls-btn{
        border: none;
        padding: 0 !important;
        background-color: $white;
    }
    .manage-div{
        width: 385px;
        margin-left: 20px;
       
    }
}
