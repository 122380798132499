@import "../../styles/layout/color.scss";

#createRole {
  .rolecreatelabel {
    color: $DuskBlue;
    cursor: pointer;
  }

  .labelcolor {
    color: $black;
  }

  .createroletext {
    color: $white;
    width: 100%;
    height: 10%;
    background: $DuskBlue 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    margin-top: 2%;
  }

  #pText {
    margin-left: 1%;
    padding: 0.5%;
  }

  #RegularUserForm {
    background-color: $white;
    width: 98%;

    .paddingTop {
      padding-top: 2%;
    }

    .red-star {
      color: $red;
    }

    .required:after {
      content: " *";
      color: $red;
    }

    .errorMessage {
      display: flex;
      justify-content: flex-start;
      color: $red;
    }

    .submitButton {
      margin-left: 1.2rem;
    }
    .InputField {
      width: 90%;
      border-radius: 7px;
      border: 1px solid $grey;
    }

    .buttonSets {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .buttonMargin {
      margin: 0% 0% 0% 10%;
    }
  }
}
