@import '../../styles/layout/color.scss';
#usertype {
  .labelPresentation {
    font: normal normal 600 16px/22px Open Sans;
    margin-left: 0;
  }
  .input-container {
    position: relative;
  }

  .createUserTypeButton {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6.8px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }

  .search-icon {
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-50%);
  }

  .SearchButton {
    padding-right: 30px;
  }
}
