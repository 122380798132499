@import "../../../styles/layout/color.scss";
.ExceptionPayment {
  &_max-widthTable {
    // max-width: 54rem;
  }
  &_tableAlignment {
    &_checkbox {
      height: 19px !important;
      width: 19px !important;
    }

    .custom-table {
      margin-top: 0 !important;

      table {
        thead {
          height: 3.45rem;
          th {
            min-width: 10rem;
          }

          th:first-child {
            min-width: max-content !important;
          }
          th:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
            min-width: max-content !important;
          }
        }
        td {
          min-width: max-content !important;
        }
      }
    }
    .custom-table table {
      overflow: hidden;
      border-radius: 0px 10px 0rem 0rem !important;
      margin-bottom: 0px;
    }

    .id {
      min-width: max-content !important;
    }
    .srNo {
      min-width: max-content !important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .branch,
    .payTo {
      min-width: 180px !important;
    }
    .journalNo {
      min-width: 240px !important;
    }
    .Cheque_DD_Date,
    .transactionDate,
    .disAmt,
    .productDesc,
    .txnIMBSName,
    .beneNameScore,
    .Bene_Bank_Acct {
      min-width: 204px !important;
    }
  }
  &_ExceptionPaymentTxt {
    color: $navy-blue;
  }
}
.regular-payment-wrapper-row {
  height: 100%;
  .Payment_DisbursmentCaseWrapper {
    height: 100%;
    background: $white;
  }
  .RegularPayment_max-widthTable {
    border: 1px solid $grey;
    border-left: 0px;
    background: $white;
    height: 100%;
    .spinnerDiv{
      margin-top: 250px;
    }
  }
}
