@import "../../../styles/layout/color.scss";

.demergeLabel {
  font-weight: bold;
  color: $tableBoldFont !important;
}

.batchId {
  font-weight: bold;
  color: $tableBoldFont !important;
}
.deMergeBtn {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  height: 100%;
}
.dtMergeTable {
  .upload_date_time {
    min-width: 210px;
  }
  .user_name {
    min-width: 210px;
  }
  .upload_status {
    min-width: 156px;
  }
  .total_count {
    min-width: 125px;
  }
}
.btn {
  padding: 0px;
  border: none;
}
.primaryBtn {
  height: 32px;
}
