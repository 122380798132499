@import "../../../styles/layout/color.scss";
.date_range_Picker {
  border: 1px solid $grey;
  display: flex;
  input[type="text"] {
    border: 0px;
    color: $black;
    height: 30px;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    position: absolute;
  }
}
