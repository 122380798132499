
@import '../../../styles/layout/color.scss';
.main-container{
    width: 95%;
    
    
    .registration-dropdown{
        border: 1px solid $gray;
        border-radius: 5px;
     
        font: normal normal normal 14px/20px Open Sans;
        letter-spacing: 0px;
        color: $dark-gray;
        
    
    }
    .subtitle{
        font: normal normal 600 12px/21px Open Sans;
        letter-spacing: 0px;
        color:$black;
        opacity: 1;
    }
    .register-datepicker{
        background:$white;
        padding: 2px;
        height: 38px !important;
        border-radius: 5px;
        
    }
    .datepicker{
        border-radius: 5px;
        min-width: 470px;
    }
    .form-select:focus{
        border-color:$gray;
        outline: 0;
        box-shadow:none
    }
    .red-star { 
        color: $red; 
    } 
    .required:after {
         content: " *";
          color: $red; 
    }
    .errorMessage { 
        display: flex; 
        justify-content: flex-start; 
        color: $red; 
        margin-top: 8px;
    } 
    .loaderDiv{
        width: 99%;
    }
}

.dynamicFormWrapper .selectInput {
    height: 48px;
    width: 100%;
    color: $black;
}

.dynamicFormWrapper .customButtonGreen {
    color: $black;
}