@import "../../../styles/layout/color.scss";
.download-btn{
    max-width: 200px;
    background-color: $navy-blue;
    border-radius: 7px;
    color: $white;
    border: 1px solid $navy-blue ;
    min-height: 40px;
    padding: 5px 20px;
    font: normal normal 600 13px/17px Open Sans;
    letter-spacing: 0px;
}
.infoIcon
{
    max-width: 23px;
    margin-left: 1rem;
}
.placeholder{
    border: 2px dashed $placeHolder;
    width: 100% !important;
}
.bulkMandate{
    &-cancelBtn{
        padding: 4.5px 1rem;
        border-radius: 5px !important;
        border: 1px solid $Lemongreen;
        background-color: white !important;
        width: max-content !important;
   
    }
    &-submitBtn{
        background-color: $Lemongreen !important;
        font-size: 14px !important;
        font-weight: 600;
        color: $black;
        border: 0;
        border-radius: 5px !important;
        padding: 6px 1.4rem;
        border-radius: 0;
        width: max-content !important;
     
    }
}

.mycontainerstyle {
    min-height: 350px;
    height: auto;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $navbargrey;
    border-radius: 5px;
  }


  .reportui{
    label{
    
        font: normal normal 600 12px/21px Open Sans;
        letter-spacing: 0px;
    }
    .Drop{
        // min-width:300px;
        border: 1px solid $grey;
        border-radius: 5px;
    }
    .css-1r2zxuz-menu{
        max-height:150px;
        overflow-Y:auto;
    }
}

.loaderDiv{
    width: 99%;
    margin: auto;
}

.btnmargin{
margin-top: 45px;
}
.common-header{
    font: normal normal 600 15px/22px Open Sans;
    letter-spacing: 0px;
    color: $navy-blue;
    opacity: 1;
  
  }

.headername{
    color: $black;
}


.uploadComponentLabel{
    .placeholder{
        background-color:transparent;
        cursor: pointer;
        border:dashed 1px grey;
        color: $tableBoldFont;
        text-align: center;
        padding-top: 20px;
        font-weight: bold;
        font-size: large;
        width: 420px;
        height: 80px;
    }
    
    .text-wrap{
        word-wrap: break-word;
        width: 410px;
        color: $tableBoldFont;
    }
}