
@import "../../styles/layout/color.scss";
.accounts-inputs{
    border-radius: 0px;
    border:1px solid $gray;
    padding: 0.2rem;
    height: 2rem !important;
}
.exportBtn
{
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $tableBoldFont;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 4px 35px;
    cursor: pointer;
    width: max-content;
}
.accounts-dropdown{
    border-radius: 0px;
    border: 1px solid $gray;
    padding: 0.2rem;
    min-width: 157px;
    
}
.form-select:focus{
    border-color: $gray;
    outline: 0;
    box-shadow:none
}
