@import "../../../styles/layout/color.scss";

.linkButton {
    color: $tableBoldFont;
    font-size: larger;
    font-weight: bold;
    padding-bottom: 5px;
    padding-left: 0px;
}
.demergeSystemCountWrapper{
    width: 100vw;
}