@import '../../../styles/layout/color.scss';
.reportsui{
    .secondaryBtn{
        width: 90px;
        height: 36px;
        padding: 0px !important;
        border-radius: 4px;
        color: $navy-blue;
        border-color: transparent;
        background: transparent;
    }
}
.progress{
    --bs-progress-height: 0.5rem;
    width:90%
    
    
}
.progress-bar{
    // --bs-progress-bar-bg:$navy-blue;
    background-color: $navy-blue;
    width:50%
}
