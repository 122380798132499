@import "../../styles/layout/color.scss";

.Header {
  position: relative;
  z-index: 900;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 14.81px 44.75px 14.81px 44.75px;
  height: 80px;
  &_DropdownItem
  {
    // padding: 15px 10px 15px 10px !important;
  }
  .image-div {
    width: 280px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
    img {
      width: 180px;
    }
  }

  .dropdown-toggle {
    color: $DuskBlue !important;
    background-color: $white !important;
    border: none !important;
    opacity: 1 !important;
    border: none !important;
  }

  .dropdown-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
  }
}
