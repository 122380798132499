@import "../../../styles/layout/color.scss";

.dynamicFormWrapper {
    .inputDiv {
      p {
        color: $black;
        font-size: 15px;
        font-weight: 600;
      }
    }
    .textInput {
      min-height: 40px;
      width: 30%;
      border: solid 1px $grey;
    }
    .textInput:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $placeHolder;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    .selectInput {
      height: 48px;
    }
    .bottomDiv {
      background: $white;
      border-radius: 8px;
      padding: 20px;
      height: 450px;
      overflow: auto;
    }

    .textLabelRed {
      color: $red !important;
      font-size: 15px;
      font-weight: 600;
    }

  }