@import "../../../styles/layout/color.scss";

.search_bar {
  display: flex;
  border: 1px solid $grey;
  background-color: $white;
  padding: 4px;
  min-height: 2.6rem;
  border-radius: 4px;

  input.search {
    border: 0px;
    width: 98%;
  }

  input.search:focus {
    outline: 0px;
  }

  .searchIcon {
    padding-right: 15px;
  }

  .searchButton {
    background-color: $DuskBlue !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $white;
    border: 0;
    border-radius: 5px !important;
    padding: 6.8px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
}