@import "../../../../styles/layout/color.scss";

#createEnachVendor {
  .blueFonts {
    margin: 4% 2%;
    color: $navy-blue;
    cursor: pointer;
  }
  .blackFonts {
    color: $black;
  }

  .whiteBG {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #0000001a;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    margin: 0% 2% 2% 2%;
  }

  .blueBG {
    background: $DuskBlue 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .titleFont {
    color: $white;
    margin: 2%;
  }
  .field {
    margin: 0% 2% 2% 2%;
  }
  .paddingTop {
    padding-top: 2%;
  }

  .submitButton {
    padding-top: 3%;
    padding-bottom: 1%;
  }

  .red-star {
    color: $red;
  }

  .required:after {
    content: " *";
    color: $red;
  }

  .errorMessage {
    display: flex;
    justify-content: flex-start;
    color: $red;
  }

  .select {
    height: 37px !important;
  }

  .setmargin {
    margin: 2%;
  }

  .buttonSets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
  }
}
@import "../../../../styles/layout/color.scss";

#createEnachVendor {
  .blueFonts {
    margin: 4% 2%;
    color: $navy-blue;
    cursor: pointer;
  }
  .blackFonts {
    color: $black;
  }

  .whiteBG {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #0000001a;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    margin: 0% 2% 2% 2%;
  }

  .blueBG {
    background: $DuskBlue 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .titleFont {
    color: $white;
    margin: 2%;
  }
  .field {
    margin: 0% 2% 2% 2%;
  }
  .paddingTop {
    padding-top: 2%;
  }

  .submitButton {
    padding-top: 3%;
    padding-bottom: 1%;
  }

  .red-star {
    color: $red;
  }

  .required:after {
    content: " *";
    color: $red;
  }

  .errorMessage {
    display: flex;
    justify-content: flex-start;
    color: $red;
  }

  .select {
    height: 37px !important;
  }

  .setmargin {
    margin: 2%;
  }

  .buttonSets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
  }
}
