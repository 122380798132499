@import "../../../styles/layout/color.scss";
.InputField {
  background: $white 0% 0% no-repeat padding-box !important;
  border: 1px solid $grey;
  border-radius: 2px;
  opacity: 1;
  height: 38px;
  width: -webkit-fill-available;
  color: $black;
  font-size: 12px;
  padding: 10px;
  width: 100%;
}
