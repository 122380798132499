@import '../../../../../styles/layout/color.scss';

.mandate-indusland-table{
    .srno,.bank,.companyCode,.download{
        font-weight: bold;
        color: $tableBoldFont;
      }
}
.indusland-input{
    background-repeat: no-repeat;
    height: 32px;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: $black;
    border: 1px solid $gray;
    border-radius: 3px;
}
.indusland-dropdown{
    border: 1px solid $dark-gray;
    border-radius: 3px;
    padding: 0.2rem;
    min-width: 157px;
    height:2rem;
    font: normal normal 600 10px/14px Open Sans;
}
.form-select:focus{
    border-color: $gray;
    outline: 0;
    box-shadow:none
}
.indusIndReportHeader{
    .exportBtn{
      height: 38px !important;
    }
    .selectInput{
      height: 38px !important;
      min-width: 175px !important;
    }
  }