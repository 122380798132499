@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/common.scss"; 
$bottomButtonSuccessSelector:selector-nest(".bottombtn", "&_submitBtn");
$bottomButtonBackSelector: selector-nest(".bottombtn", "&_backBtn");

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customer_account_details {
  .errorMessage {
    color: red;
    font-size: 12px;
    padding: 4px 0px;
  }
  .field_name {
    font-weight: bold;
    font-size: 15px;
  }
  #{$bottomButtonSuccessSelector} {
    background-color: $Lemongreen;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content;
  }
  #{$bottomButtonBackSelector} {
    border: 1px solid $Lemongreen;
    background-color: $white;
    @extend #{$bottomButtonSuccessSelector};
  }
}
