@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/mixins.scss";

$submitBtn: selector-nest(".verifyAccountButtons", "&_submitBtn");
$downloadBtn: selector-nest(".verifyAccountButtons", "&_downloadBtn");
$cancelBtn: selector-nest(".verifyAccountButtons", "&_cancelBtn");
$uploadFileDiv: selector-nest(".verifyAccount", "&_UploadFile");
#{$submitBtn} {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  max-height: 2.6rem;
}
#{$downloadBtn} {
  background-color: $DuskBlue !important;
  width: max-content !important;
  border: 0;
  border-radius: 5px;
  padding: 2.5px 8px;
  color: $white;
  font-size: 12px !important;
  border-radius: 5px;
  img {
    height: 13px;
    margin-right: 4px;
    margin-bottom: 3px;
  }
}

#{$uploadFileDiv}
{
  width: 52%;
}
#{$cancelBtn} {
  padding: 4.5px 1rem;
  border: 1px solid $Lemongreen;
  background-color: $white;
  @extend #{$submitBtn};
}

@include customWidthMedia(0px,877px)
{
  #{$downloadBtn} {
    margin-left: 0.7rem;
    max-height: max-content !important
    ;
  }
}
.manage-header {
  background-color: $navy-blue;
  height: 2.5rem;

  .sub-header {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: $white;
    opacity: 1;
    // padding-top: 12px;
    // padding-left: 20px;
  }
}
.account-verfication-dropdown {
  padding: 0px;
  // border: 1px solid #DCDCDC;
  // border-radius: 3px;
  // width: 500px;
  border: 1px solid $gray;
  border-radius: 5px;

  font: normal normal normal 14px/20px Open Sans;
  letter-spacing: 0px;
  color: $dark-gray;
}
.manage-title {
  font: normal normal 600 12px/21px Open Sans;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
}
.manage-subtitle {
  font: normal normal 600 14px/21px Open Sans;
  letter-spacing: 0px;
  color: $black;
}
.dropzone-manage {
  border: 2px dashed $gray;
  border-radius: 5px;
  min-height: 100px;
  max-width: 75%;
}
.verifyDropZone {
  &_DropzoneWrapper {
    position: relative;
    min-height: 39rem;
    background-color: $white;
    border: 1px solid $grey;
    &_label {
      font-size: 12px !important;
    }
    &_uploadlabel {
      font-size: 14px !important;
      position: relative;
      top: 0.5rem;
    }
    button {
      background-color: $DuskBlue !important;
      width: 100% !important;
      border: 0;
      border-radius: 5px;
      padding: 2.5px 8px;
      color: $white;
      font-size: 12px !important;
      border-radius: 5px;
      img {
        height: 13px;
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
    &_Table-Head {
      display: flex !important;
      align-items: center !important;
      border-radius: 10px 0rem 0rem 0rem;
      background-color: $DuskBlue;
      padding: 0.5rem 8%;
      width: 100%;
      p {
        color: $white !important;
        position: relative;
        top: 0.6rem;
        font-size: 14px;
      }
    }
    &_required {
      color: $red !important;
    }

    @include customWidthMedia(990px, 1060px) {
      &_UploadWrapper {
        width: 100%;
        height: 18rem;
        margin-bottom: 9rem;
        border: 2px dashed $grey;
        &_dragTxt {
          color: $navy-blue;
        }
        &_browseTxt {
          color: $tableBoldFont !important;
          text-decoration: underline !important;
          cursor: pointer;
          &:hover {
            text-shadow: 0.2px 0.2px rgb(3, 3, 58) !important;
          }
        }
        &_uploadIcon {
          width: 14px;
        }
      }
    }

    &_UploadWrapper {
      width: 100%;
      height: 12.5rem;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed $grey;
      &_dragTxt {
        color: $navy-blue;
      }
      &_browseTxt {
        color: $tableBoldFont !important;
        text-decoration: underline !important;
        cursor: pointer;
        &:hover {
          text-shadow: 0.2px 0.2px rgb(3, 3, 58) !important;
        }
      }
      &_uploadIcon {
        width: 14px;
      }
    }
    &_bottomButtons {
      position: absolute;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
      bottom: 1rem;
      gap: 7px;
      button {
        background-color: $Lemongreen !important;
        font-size: 14px !important;
        font-weight: 600;
        color: $black;
        border: 0;
        border-radius: 5px !important;
        padding: 6px 1.4rem;
        border-radius: 0;
        width: max-content !important;
      }
      &_outlineBtn {
        button {
          padding: 4.5px 1rem;
          border-radius: 5px !important;
          border: 1px solid $Lemongreen;
          background-color: white !important;
          width: max-content !important;
          img {
            height: 18px;
          }
        }
      }
    }
  }
}
