.changepwdwrapper{
    background: url("../../../Images/passwordbackground.png") no-repeat white;
    background-size:100% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    .password-card{
        margin-top: 100px;
        width: 400px;
        background: white;
        padding: 20px;
        border-radius: 10px;
        height: fit-content;
    }
}