@import "../../styles/layout/color.scss";

.sidebar {
  // width: 280px;
}

.sidebar-head {
  height: 3.5rem;
  // box-shadow: 3px 3px 6px $GhostWhite;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -3px 1px 3px 3px $GhostWhite;
}

.sidebar-head-2 {
  // height: 3.5rem;
  // box-shadow: 3px 3px 6px $GhostWhite;
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -3px 1px 3px 3px $GhostWhite;
}

.menu {
  font-weight: 600;
  color: $drop-zonetext;
  margin-left: 1.5rem;
}

// 
.sidebar-body {
  overflow-y: auto;
  padding-top: 1.25rem;
  // height: calc(100vh - 3.5rem);
  height: 150vh;
  background: $navy-blue;
}
.sidebar-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
  background-color: $GhostWhite;
  border-radius: 10px;
}
.sidebar-body::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: $GhostWhite;
}
.sidebar-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $navy-blue;
}

.nav-list {
  list-style-type: none;
  margin-top: 0.25rem;
  padding-left: 0;
}

.nav-list .nav-item {
  margin-top: 0.25rem;
}

.nav-item {
  gap: 12px;
  display: flex;
  padding: 0px 10px;

  a {
    width: 100%;
  }

  a:hover {
    text-decoration: none;
  }
}

.nav-list-item {
  gap: 12px;
  padding: 0.5rem 1rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
  color: $white;
  text-decoration: none;
}

.nav-list-item.active,
.nav-list-item:hover,
.nav-list-item.active .menu-icon,
.nav-list-item:hover .menu-icon {
  color: $white;
  background-color: $exportBlue !important;
  border-radius: 6px;
}

.nav-list-item .menu-icon {
  padding: 0.25rem;
  color: $drop-zonetext;
}

.sub-menu-item {
  text-decoration: none;
  color: $white;
  font-size: 14px;
}

.nav-sub-menu .nav-item {
  margin-top: 0.5rem;
}

.side-bar.closed,
.side-bar.closed .sidebar-head,
.side-bar.closed .sidebar-body {
  width: 3.5rem;
}

.side-bar.closed .sidebar-head .head-logo,
.side-bar.closed .menu-name,
.head-toggle {
  display: none;
}

.side-bar.closed .menu {
  opacity: 0;
  margin: 0;
}

.side-bar.closed .nav-list-item {
  padding: 0.5rem 0.75rem;
}

.side-bar.closed .head-toggle,
.head-logo {
  display: block;
  cursor: pointer;
}

.user-action {
  cursor: pointer;
}

.headerMenu {
  position: absolute;
  height: 2.5rem;
  width: 100px;
  background-color: $white;
  top: 2.5rem;
  right: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 3px 3px 6px $GhostWhite;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.headerMenu-item {
  display: flex;
  text-decoration: none;
  color: $footergrey;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
}

.headerMenu.show {
  opacity: 1;
  visibility: visible;
}

.nav-sub-menu {
  list-style-type: none;
  padding-left: 0rem;

  .nav-item {
    padding: 0px;
  }
}

.sidebar {
  height: 100%;

  .flex-column {
    height: 100%;
  }
}
.subActive{
  font-weight: 700 !important;
}
