@import "../../../styles/layout/color.scss";
.account_reports {
  height: 100vh;
  margin: 60px;
  .report_details {
    height: 60vh;
    background: $white 0% 0% no-repeat padding-box;
    padding: 40px;
    border-radius: 7px;
  }
  .set_top_margin {
    margin-top: 20px !important;
  }
}
