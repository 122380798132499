@import '../../../styles/layout/color.scss';
#formDropDown{
.formSelect{
    height: 38px !important;
    width: 100% !important;
    background: $white 0% 0% no-repeat padding-box !important;
    border: 1px solid $grey;
    border-radius: 2px;
    font-size: 12px;
}
}
