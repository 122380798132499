@import "./color.scss";
.common-header {
  font: normal normal 600 16px/22px Open Sans !important;
  letter-spacing: 0px;
  color: $navy-blue;
  opacity: 1;
}
.singleValidationDropdown {
  #formDropDown .formSelect {
    height: 38px !important;
    width: 100% !important;
    background: $white 0% 0% no-repeat padding-box !important;
    border: 1px solid $grey;
    border-radius: 2px;
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tableTxt {
  color: $tableBoldFont;
}

@for $i from 0 through 50 {
  .mct-#{$i} {
    margin-top: $i * 0.25rem;
  }
  .mcb-#{$i} {
    margin-bottom: $i * 0.25rem;
  }
  .mcl-#{$i} {
    margin-left: $i * 0.25rem;
  }
  .mcr-#{$i} {
    margin-right: $i * 0.25rem;
  }
}
