@import "../../../styles/layout/color.scss";

.dtMergeTable {
  .valid {
    font-weight: bold;
    color: $tableBoldFont;
  }
  .invalid {
    font-weight: bold;
    color: $tableBoldFont;
  }
}
.uploadBtn
{
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  height: 100%;
}
.rptTableButton {
  .approveBtn {
    background: $approveBlue;
    border: 1px solid $approveBlue;
    color: $white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    min-width: 80px;
  }
  .rejectBtn {
    background: $dark-green;
    border: 1px solid $dark-green;
    color: $white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    min-width: 80px;
  }
}
.dtRepresentationTable{
  .date_time{
    min-width: 190px;
  }
  .user_name{
    min-width: 254px;
  }
  .loaderDiv{
    width: 99%;
  }
}