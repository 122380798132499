@import "./layout/color.scss";
@import "./layout/mixins.scss";
@import "./layout/font.scss";
@import "./layout/common.scss";


.loaderDiv {
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
    background-color: $formBorderGrey;
  }

  .toggle-wrapper{
    display: flex;
    justify-content: flex-end;
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      margin: 10px;
    }
    
    .toggle-switch .toggle-input {
      display: none;
    }
    
    .toggle-switch .toggle-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 24px;
      background-color: $exportBlue;
      border-radius: 34px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .toggle-switch .toggle-label::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      background-color: $white;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s;
    }
  
    .toggle-switch .toggle-input:checked + .toggle-label {
      background-color: $dark-green;
    }
    
    .toggle-switch .toggle-input:checked + .toggle-label::before {
      transform: translateX(16px);
    }
  
    .toggle-switch.light .toggle-label {
      background-color: $togglebg;
    }
    
    .toggle-switch.light .toggle-input:checked + .toggle-label {
      background-color: $togglebg2;
    }
    
    .toggle-switch.light .toggle-input:checked + .toggle-label::before {
      transform: translateX(6px);
    }
    .toggle-switch.dark .toggle-label {
      background-color: $togglebg3;
    }
    
    .toggle-switch.dark .toggle-input:checked + .toggle-label {
      background-color: $togglebg4;
    }
    
    .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
      transform: translateX(16px);
    }
    .label-name{
      margin-top: 13px;
      font-size: 16px;
    }
  }
.BtnCustomWrapper{
  .Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: #0163E0;
  }
  
  /* plus sign */
  .sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign svg {
    width: 30px;
  }
  
  .sign svg path {
    fill: white;
  }
  /* text */
  .text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: .3s;
  }
  /* hover effect on button width */
  .Btn:hover {
    width: 150px;
    border-radius: 40px;
    transition-duration: .3s;
  }
  
  .Btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
    padding-left: 10px;
  }
  /* hover effect button's text */
  .Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
  /* button click effect*/
  .Btn:active {
    transform: translate(2px ,2px);
  }
}
.customRefreshButton {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  height: 100%;
}