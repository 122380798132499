@import './../../styles/layout/color.scss';
.custom-modal-header {
    background-color: $navy-blue;
}
.custom-modal-title {
    color: $white;
}
.custom-modal-button-danger {
    background-color: $red;
}
.custom-modal-button-danger:hover {
    background-color: $red;
    outline: $black;
}
.bufferMargin {
    margin-left: 10px;
}