@import "../../../../styles/layout/color.scss";
.presentationStatus {
  .SearchButton {
    top: 116px;
    left: 783px;
    width: 175px;
    height: 38px;
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $formBorderGrey;
    border-radius: 3px;
    opacity: 1;
  }
  &_uploadBtn {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
    height: 100%;
  }
  .labelPresentation {
    font-style: normal normal 600 18px/22px Open Sans;
    letter-spacing: 0px;
    color: $navy-blue;
    opacity: 1;
    top: 120px;
    left: 270px;
    width: 180px;
    height: 22px;
    font-size: 20px;
  }
  .selectInput {
    min-width: 175px !important;
    height: 38px !important;
    font-size: 13px;
  }
}

.dtMergeTable .loaderDiv {
  width: 99%;
}
.upload_date_time {
  min-width: 200px !important;
}
.file_name {
  min-width: 235px !important;
}
