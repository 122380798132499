@import "../../../styles/layout/color.scss";

.btn {
  .primaryBtn {
    background: $Lemongreen;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    width: max-content !important;
    cursor: pointer;
  }

  .secondaryBtn {
    background: $white;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 8px 35px !important;
    cursor: pointer;
    width: max-content !important;
  }

  .rejectBtn {
    background-color: $red !important;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
  }

  .exportBtn {
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $tableBoldFont;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
    width: max-content !important;

    &:hover {
      background-color: $white;
      border-radius: 6px;
      border: 1px solid $tableBoldFont;
      color: $black;
      text-transform: capitalize;
      font-size: 14px;
      padding: 6px 35px;
      cursor: pointer;

    }
  }

  .approveBtn {
    background: $dark-green;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
  }

  .filterBtn {
    background: $footergrey 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
  }

  .rejectBtn {
    background: $red;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
  }

  .downloadBtn {
    background: $Lemongreen;
    font-weight: bold;
    border-radius: 6px;
    border: 1px solid $Lemongreen;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;
  }

  .transaparentBtn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .cancelBtn {
    border: 1px solid $Lemongreen !important;
    width: 120px !important;
    border-radius: 6px !important;
    color: $black !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    height: 32px !important;
    background-color: transparent;
    font-weight: bold !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .btn {
    .primaryBtn {
      background: $Lemongreen;
      border-radius: 6px;
      border: 1px solid $Lemongreen;
      color: $black;
      text-transform: capitalize;
      font-size: 14px;
      padding: 6px 35px;
      cursor: pointer;
      font-size: 9px;
    }

    .secondaryBtn {
      background: $btnBlue;
      border-radius: 6px;
      border: 1px solid $Lemongreen;
      color: $black;
      text-transform: capitalize;
      font-size: 14px;
      padding: 6px 35px;
      cursor: pointer;
      font-size: 9px;
      --bs-btn-padding-y: 0rem;
    }
  }
}

@media (max-width: 440px) {
  .btn {
    .approveBtn {
      font-size: 55% !important;
      margin-right: 0% !important;
    }
  }
}

@media (max-width: 780px) {
  .btn {
    .approveBtn {
      font-size: 55% !important;
    }

    .rejectBtn {
      width: 100px;
    }
  }
}

@media (max-width: 500px) {
  .btn {
    .rejectBtn {
      min-height: 34px !important;
    }
  }
}