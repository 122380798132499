@import "../../../styles/layout/common.scss";

.disbursementReport {
  &_disbursementReportTxt {
    color: $DuskBlue;
    font-size: 16px;
  }

  &_inputContainer {
    box-shadow: 1px 1px $grey;
    background-color: $white;
    border-radius: 5px;
    border: solid $grey 1px;

    .dynamicFormWrapper .customDatepicker {
      width: 100%;
      height: 41px;
      border: 1px solid $formBorderGrey;
      border-radius: 0.375rem;
      padding-left: 10px;
      outline: none;
    }

    .dynamicFormWrapper .selectInput {
      height: 41px;
      width: 100%;
      border: 1px solid $formBorderGrey;
      border-radius: 0.375rem;
      padding-left: 10px;
      outline: none;
      outline: none;
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      overflow: visible;
    }

  }

  &_datetxt {
    font-size: 12px !important;
  }
}