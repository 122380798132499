@import "../../styles/layout/color.scss";

.pageBtn {
  color: $red;
  background: $white 0% 0% no-repeat padding-box;
  border: 0.5px solid $navy-blue;
  border-radius: 6px;
  min-width: 30px;
  max-width: min-content;
  height: 30px;
  margin: 0px 10px;
}
.custom-table {
  .verticalScrollWrapper {
    overflow: auto;
    // position: relative;
    max-height: 74vh;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
      background-color: $GhostWhite;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: $GhostWhite;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $navy-blue;
    }
  }
  table {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 0px;
    thead {
      background: $navy-blue !important;
      color: $white;
      th {
        border-top: 1px solid $customtableBorder;
        border-bottom: 1px solid $customtableBorder;
        font-weight: 500;
        background: $navy-blue;
        color: $white;
        vertical-align: middle;
      }
    }
    tr {
      vertical-align: middle;
    }
    td {
      min-width: 100px;
      background: white;
    }
    th {
      min-width: 100px;
    }
  }
  &_btn-position {
    border-top: 1px solid $customtableBorder;
    border-bottom: 1px solid $customtableBorder;
    position: sticky;
    left: 0px;
  }
  &_green-btn {
    background-color: $dark-green;
    color: $white;
    border: 0;
    font-weight: 600;
    border-radius: 4px;
    &:hover {
      background-color: $dark-green;
    }
  }
  &_yellow-btn {
    background-color: $Lemongreen;
    color: $white;
    border: 0;
    font-weight: 600;
    border-radius: 4px;
    &:hover {
      background-color: $Lemongreen;
    }
  }
  &_red-btn {
    background-color: $red;
    color: $white;
    border: 0;
    font-weight: 600;
    border-radius: 4px;
    &:hover {
      background-color: $red;
    }
  }
  &_icon-btn {
    margin-top: 1.1rem;
    max-height: 2.4rem;
    min-width: 2.4rem;
    background-color: $Lemongreen;
    border-radius: 4px;
    &:hover {
      background-color: $Lemongreen;
    }
  }
  &_red-btn {
    background-color: $red;
    color: white;
    font-weight: 600;
    border: 0;
    border-radius: 4px;
    &:hover {
      background-color: $red;
    }
  }
  table {
    overflow: visible !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 600;
    outline: 1px solid #dee2e6 !important;
  }
}

.custom-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
  background-color: $GhostWhite;
  border-radius: 10px;
}

.custom-table::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: $GhostWhite;
}
.custom-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $navy-blue;
}

.no-record-found {
  position: sticky;
  left: 0px;
}
.fixed-row {
  left: 0;
  position: sticky;
  top: auto;
  background-color: $white;
  z-index: 500;
  outline: 1px solid #dee2e6 !important;
  // width: 125px;
}
.regularPaymentButtonDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.customFailedErrorColor {
  background-color: #EF0107 !important;
}