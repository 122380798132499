@import "../../../styles/layout/color.scss";

.statusTxt {
  color: $navy-blue;
}
.loaderDiv {
  width: 99%;
}
.downloadbtn {
  width: max-content !important;
  background-color: $Lemongreen;
  border: 1px solid $Lemongreen;
  border-radius: 3px;
  font: 14px bold;
  padding: 4px 8px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
