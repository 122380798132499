@import "../../styles/layout/color.scss";
#userpage{
   
  .labelPresentation{
    font: normal normal 600 16px/22px Open Sans;
    margin-left: 0;
  }
  
  
  .input-container {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-50%);
  }
  
  .SearchButton {
    padding-right: 30px;
  }

  .icon{
    padding: 10px;
    cursor: pointer;
  }
  .delete_icon{
    padding: 10px;
  }
  
  .exportBtn {
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $tableBoldFont;
    color: $black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 6px 35px;
    cursor: pointer;

    &:hover {
      background-color: $white;
      border-radius: 6px;
      border: 1px solid $tableBoldFont;
      color: $black;
      text-transform: capitalize;
      font-size: 14px;
      padding: 6px 35px;
      cursor: pointer;

    }
  }
  .createUserBtn
  {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6.8px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }

  .exportBtn.disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
  }
  .custom-table{
    .no{
      min-width: 50px;
    }
  }  
}




 