@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/common.scss";
$bottomButtonSuccessSelector: selector-nest(".bottombtn", "&_submitBtn");
$bottomButtonBackSelector: selector-nest(".bottombtn", "&_backBtn");
$bottomButtonDisabledSuccessSelector: selector-nest(
  ".bottombtn",
  "&_disabledSubmitBtn"
);
$bottomButtonDisabledBackSelector: selector-nest(
  ".bottombtn",
  "&_disabledBackBtn"
);


.bank_details {
  .errorMessage {
    color: $red;
    font-size: 12px;
    padding: 4px 0px;
  }
  .field_name {
    font-weight: bold;
    font-size: 15px;
  }
  #{$bottomButtonSuccessSelector} {
    background-color: $Lemongreen;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }

  #{$bottomButtonDisabledSuccessSelector} {
    background-color: lighten($color: $Lemongreen, $amount: 10);
    font-size: 14px;
    font-weight: 600;
    color: lighten($color: $black, $amount: 50);
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
  #{$bottomButtonBackSelector} {
    border: 1px solid $Lemongreen;
    background-color: $white;
    @extend #{$bottomButtonSuccessSelector};
  }

  #{$bottomButtonDisabledBackSelector} {
    border: 1px solid lighten($color: $Lemongreen, $amount: 20);
    background-color: lighten($color: $white, $amount: 50);
    @extend #{$bottomButtonDisabledSuccessSelector};
  }
  &-btnCounter {
    width: max-content;
    background: lighten($color: $Lemongreen, $amount: 20) 0% 0% no-repeat
      padding-box;
    font-size: 14px;
    font-weight: 600;
    color: lighten($black, 20);
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
  &-btn-pdfLink {
    background: $Lemongreen 0% 0% no-repeat padding-box;
    // boxShadow: '0px 3px 6px $Lemongreen';
    border: 1px solid $Lemongreen;
    border-radius: 4px;
    width: max-content;
    opacity: 1;
    width: 120;
    height: 34px;
    padding: 10px;
    // padding: '6px 1.4rem';
    text-decoration: none;
    color: $black;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}
