@import "../../../styles/layout/color.scss";

#RegularUserForm {
  .paddingTop {
    padding-top: 4%;
  }
  .submitButton {
    margin: 1%;
    padding-top: 3%;
  }
  .red-star {
    color: $red;
  }
  .required:after {
    content: " *";
    color: $red;
  }
  .errorMessage {
    display: flex;
    justify-content: flex-start;
    color: $red;
    margin-top: 8px;
  }
  .select {
    height: 37px !important;
  }
}

.dynamicFormWrapper {
  .cardHeader {
    background-color: $navy-blue;
    color: $white;
    font-weight: 600;
  }
  .customButtonGreenInverted:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
  }
  .customButtonGreenInverted {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $white;
    font-weight: 500;
    width: 160px;
    min-height: 40px;
  }
  .customButtonGreenInverted {
    border: 1px solid $Lemongreen;
  }
  .customButtonGreen:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
  }
  .customButtonGreen {
    border: 1px solid $Lemongreen;
    color: $white;
    background: $Lemongreen;
    font-weight: 600;
    width: 160px;
    min-height: 40px;
  }
  .customButtonGreen {
    border: 1px solid $Lemongreen;
  }
  .inputDiv {
    p {
      color: $black;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .textInput {
    height: 48px;
  }
  .textInput:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $placeHolder;
  }
  .selectInput {
    height: 48px;
  }
  .bottomDiv {
    background: $white;
    border-radius: 8px;
    padding: 20px;
    height: 450px;
    overflow: auto;
  }
  .headerCheckBox {
    .form-check {
      padding: 8px 40px;
      background: $white;
      border-radius: 8px;
      margin: 2rem 0px;
    }
    input {
      height: 20px;
      width: 20px;
      border: 2px solid $navy-blue;
      border-radius: 0px !important;
      background: transparent;
    }
    label {
      font-size: 20px;
      font-weight: 500;
      color: $black;
      margin-left: 10px;
    }
  }
  .checkBoxForm {
    flex-flow: wrap;
    input {
      height: 20px;
      width: 20px;
      border: 2px solid $navy-blue;
      background: transparent;
    }
    input:checked {
      background-color: $navy-blue;
      border-color: $navy-blue;
    }
    input:focus {
      border-color: $navy-blue;
      box-shadow: none;
    }
    label {
      font-size: 16px;
      font-weight: 500;
      padding-top: 3px;
      margin-left: 8px;
    }
  }
  .dropDownPlaceholder {
    color: $placeHolder;
  }
  .customDatepicker {
    width: 100%;
    height: 48px;
    border: 1px solid $formBorderGrey;
    border-radius: 0.375rem;
    padding-left: 10px;
    outline: none;
  }
  .customDatepicker:focus {
    border: 1px solid $placeHolder;
  }
  .textLabelRed {
    color: $red !important;
    font-size: 15px;
    font-weight: 600;
  }
  .dropWrapper {
    height: 80px;
    border: 2px dashed $borderGrey;
    color: $navy-blue;
    font-weight: 600;
    border-radius: 7px;
  }
  .uploadContent {
    word-break: break-word;
    max-width: 300px;
  }
  .buttonCenter {
    margin-left: 30%;
  }
}
.loaderDiv {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  background-color: $GhostWhite;
}
