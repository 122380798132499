
@import "../../../styles/layout/color.scss";

.paymentReversaldownloadIcon
{
    background-color: $DuskBlue !important;
    width: 100% !important;
    border: 0;
    border-radius: 5px;
    padding: 2.5px 8px;
    color: $white;
    font-size: 12px !important;
    border-radius: 5px;
    img {
      height: 13px;
      margin-right: 4px;
      margin-bottom: 3px;
    }  
}
.manage-reversal-container{
    width: 95%;
    margin-left: 30px;
    .dropdownContainer
    {
        width: 45%;
    }
    .manage-inputs{
        padding: 0px;
        border: 1px solid $gray;
        border-radius: 5px;
        opacity: 1;
        min-width:10px;
        margin-left: 0.7rem;
    }
    .sub-container{
        width: 65%;
        margin-left: 26px;
       
    }
    .label{
        font: normal normal 600 12px/21px Open Sans;
        letter-spacing: 0px;
        color: $black;
        opacity: 1;
    }
   
    .upload-file-text{
        font: normal normal 600 14px/21px Open Sans;
        letter-spacing: 0px;
        color: $black;
        opacity: 1;
    }
    .download-btn{
        max-width: 198px;
        background-color: $navy-blue;
        border-radius: 7px;
        color: $white;
 
        font: normal normal 600 12px/17px Open Sans;
        letter-spacing: 0px;
    }
    .manage-dropzone{
        border: 3px dashed $gray;
        border-radius: 7px;
        margin-top: 15px;
        min-height:120px;
    }
    .dropzone-p{
        font: normal normal 600 12px/17px Open Sans;
        letter-spacing: 0px;
        opacity: 1;
        text-align: center;
        margin-top: 25px;
    
    }
    .dropzone-text{
        color:$drop-zonetext;
        text-decoration: underline;
    }
}