@import "../../../styles/layout/color.scss";

.dtMergeTable {
  .batch_id {
    font-weight: bold;
    color: $tableBoldFont;
  }
  .download {
    .form-select:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none;
      border: 1px solid $Lemongreen;
    }
    .form-select {
      border: 1px solid $Lemongreen;
      width: 150px;
    }
    .select-menu {
      border: 1px solid $Lemongreen;
    }
  }
}
.btn {
  padding: 0px;
  border: none;
}
.primaryBtn {
  height: 38px !important;
}
.selectInput {
  min-height: 40px;
  min-width: 110px;
}
.mergeBtn {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  height: 100%;
}
