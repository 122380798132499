@import "../../../styles/layout/color.scss";

@media (min-width: 1366px) {

    .errorMessage {
        color: $red;
        font-size: 12px;
        padding: 4px 0px;
    }

    .loaderDiv {
        // width: 50px;
        margin: auto;
    }


    .SearchButton {
        margin-top: 15px;
    }

    .button-nav {
        background-color: $DuskBlue;
        /* Green */
        border: none;
        color: $white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 10px;
    }

    .statusLabel {
        font-weight: bold;
        color: $tableBoldFont;
    }

    button:hover {
        background-color: $DuskBlue;
    }

}


.mycontainerstyle {
    min-height: 350px;
    height: auto;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $navbargrey;
    border-radius: 5px;
}

.cleardata {
    width: 5px;
}

.view {
    width: 70px;
    padding: 0px !important;
    border-radius: 0px !important;
}

// STATUS PAGE UI
.statusui {
    label {
        font: normal normal 600 10px/21px Open Sans;
        letter-spacing: 0px;
        margin: 0px;
    }

    .Drop {
        min-width: 285px;
        border: 1px solid $grey;
        border-radius: 5px;

        :default {
            color: $grey;
        }

        height: 39px;

    }

    .InputField {
        min-width: 285px;
        height: 38px;
        border: 1px solid $grey;
        border-radius: 5px;

        ::placeholder {
            color: $grey;
        }

    }

    .datepicker {
        height: 30px;
    }

    .searchbtn {
        margin-top: 24px;
    }

    .primaryBtn {
        height: 36px;
        border-radius: 4px;
    }

    .secondaryBtn {
        width: 90px;
        height: 36px;
        padding: 0px;
        border-radius: 4px;
        border-color: transparent;
        background: transparent;
    }

    .exportBtn {
        border-radius: 4px !important;
    }

    .btn .transaparentBtn {
        font-size: 13px;
    }


}

.dynamicFormWrapper .inputDiv p {

    font-size: 12px;
    margin: 3px;

}

.dynamicFormWrapper .selectInput {
    min-height: 40px;
    // width: 285px;
    font-size: 13px;
    color: $placeHolder;
}

.dynamicFormWrapper .textLabelRed {

    font-size: 11px;
    margin-left: 4px;
}

// REPORT PAGE UI
.reportui {
    label {

        font: normal normal 600 12px/21px Open Sans;
        letter-spacing: 0px;
    }

    .Drop {
        // min-width:300px;
        border: 1px solid $grey;
        border-radius: 5px;
    }

    .css-1r2zxuz-menu {
        max-height: 150px;
        overflow-Y: auto;
    }
}

// MANDATE PAGE UI
.mandateui {
    .Drop {
        min-width: 170px;
        margin-top: 14px;
    }

    .css-hs83vc-control {
        border: 1px solid $black
    }

    .exportBtn {
        border-radius: 3px !important;
    }

    .css-e5iqxr-Input {
        margin: 0px;
    }

    .Newdropdown {
        height: 29px;
        border-radius: 3px;
        font-size: 13px;
        width: 146px;
    }

    .form-select:focus {
        border-color: $grey;
        outline: 0;
        box-shadow: none
    }

    .exportheight {
        height: 28px;
        padding: 5px 35px !important
    }
}

// TABLE UI
.custom-table table th {
    min-width: 153px;
}

.statusTableWrapper {

    .oid {
        min-width: 134px;
    }

    .source_system {
        min-width: 136px;
    }

    .transaction {
        min-width: 180px;
    }

    .AC_holder {
        min-width: 200px;
    }

    .res_rej_reason {
        min-width: 230px;
    }

    .account_number {
        min-width: 156px;
    }

    .rquest_id {
        min-width: 171px;
    }

    .umrn_no {
        min-width: 133px;
    }

    .mandate_status {
        min-width: 136px;
    }

    .Created_at {
        min-width: 214px;
    }

    .updated_at {
        min-width: 214px;
    }

}

.mandateTableWrapper {
    .uploder_name {
        min-width: 138px;
    }

    .date_time {
        min-width: 130px;
    }

    .fupload_status {
        min-width: 151px;
    }

    .total_records {
        min-width: 121px;
    }

    .record_success {
        min-width: 153px;
    }

    .record_reject {
        min-width: 138px;
    }

    .sms_yes {
        min-width: 128px;
    }

    .sms_no {
        min-width: 163px;
    }
}