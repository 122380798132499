@import "../../../styles/layout/color.scss";
.datepicker {
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 0px 10px;
  input[type="text"] {
    border: 0px;
    color: $black;
    width: inherit;
    height: 38px;
  }
  input:focus-visible {
    outline-offset: 0px;
    // outline: #dcdcdc;
    outline: none;
}
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    position: absolute;
    left: 130px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark-gray;
    opacity: 1; /* Firefox */
    font-size: 12px;
  }
}
.calimgstyle{
  margin-right: 7px
}
.CalendarImg{
  padding-right: 10px;
}
