@import "../../styles/layout/common.scss";
.CaseDetails {
  &_tableHeight {
    // background-color: white;
    max-height: 39rem;
    border: 1px solid $grey;
    border-left: 0px;
    background: $white;
    // height: 100%;
  }
  &_inputBox {
    width: 100%;
    min-height: 2.3rem;
    border-radius: 5px;
    border: solid $grey 2px;
  }

  &_inputBox:focus-visible {
    outline: 0px;
  }

  &_tableAlignment {
    &_checkbox {
      height: 19px !important;
      width: 19px !important;
    }

    .custom-table {
      margin-top: 0 !important;

      table {
        thead {
          height: 3.45rem;

          th {
            // min-width: 10rem;
          }
          th:first-child {
            min-width: max-content !important;
          }
        }
        td {
          // min-width: 160px;
          min-width: max-content !important;
        }
      }
    }
    .custom-table table {
      overflow: hidden;
      border-radius: 0px 10px 0rem 0rem !important;
      margin-bottom: 0px;
    }
  }
  &_CaseDetailsTxt {
    color: $DuskBlue;
  }
  &_DisbursmentCaseWrapper {
    position: relative;
    min-height: 39rem;
    background-color: $white;
    border: 1px solid $grey;
    &_label {
      font-size: 12px !important;
    }
    &_uploadlabel {
      font-size: 14px !important;
      position: relative;
      top: 0.5rem;
    }
    button {
      background-color: $DuskBlue !important;
      width: 100% !important;
      border: 0;
      border-radius: 5px;
      padding: 2.5px 8px;
      color: $white;
      font-size: 12px !important;
      border-radius: 5px;
      img {
        height: 13px;
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
    &_Table-Head {
      display: flex !important;
      align-items: center !important;
      border-radius: 10px 0rem 0rem 0rem;
      background-color: $DuskBlue;
      padding: 0.5rem 8%;
      width: 100%;
      p {
        color: $white !important;
        position: relative;
        top: 0.6rem;
        font-size: 14px;
      }
    }
    &_required {
      color: $red !important;
    }
    @media (min-width: 990px) and (max-width: 1060px) {
      &_UploadWrapper {
        width: 100%;
        height: 18rem;
        margin-bottom: 9rem;
        border: 2px dashed $grey;
        &_dragTxt {
          color: $navy-blue;
        }
        &_browseTxt {
          color: $tableBoldFont !important;
          text-decoration: underline !important;
          cursor: pointer;
          &:hover {
            text-shadow: 0.2px 0.2px rgb(3, 3, 58) !important;
          }
        }
        &_uploadIcon {
          width: 14px;
        }
      }
    }
    &_UploadWrapper {
      width: 100%;
      height: 12.5rem;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed $grey;
      &_dragTxt {
        color: $navy-blue;
      }
      &_browseTxt {
        color: $tableBoldFont !important;
        text-decoration: underline !important;
        cursor: pointer;
        &:hover {
          text-shadow: 0.2px 0.2px rgb(3, 3, 58) !important;
        }
      }
      &_uploadIcon {
        width: 14px;
      }
    }
    &_bottomButtons {
      position: absolute;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
      bottom: 1rem;
      gap: 7px;
      button {
        background-color: $Lemongreen !important;
        font-size: 14px !important;
        font-weight: 600;
        color: $black;
        border: 0;
        border-radius: 5px !important;
        padding: 6px 1.4rem;
        border-radius: 0;
        width: max-content !important;
      }
      &_outlineBtn {
        button {
          padding: 4.5px 1rem;
          border-radius: 5px !important;
          border: 1px solid $Lemongreen;
          background-color: white !important;
          width: max-content !important;
          img {
            height: 18px;
          }
        }
      }
    }
  }
}
