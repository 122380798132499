@import "../../../../styles/layout/color.scss";
#createCompany {
  .blueFonts {
    margin: 2% 0%;
    color: $navy-blue;
    cursor: pointer;
  }
  .blackFonts {
    color: $black;
  }
  .createApiUserlabel {
    color: $navy-blue;
    cursor: pointer;
  }
  .labelcolor {
    color: $black;
  }
  .createroletext {
    color: $white;
    left: 272px;
    width: 100%;
    height: 41px;
    background: $navy-blue 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    margin-top: 0%;
  }
  #pText {
    margin-left: 20px;
    padding: 8px;
    margin-top: 10px;
  }

  #RegularForm {
    background-color: $white;
    width: 95%;
    margin-left: 0%;
    .paddingTop {
      padding-top: 2%;
    }

    .red-star {
      color: $red;
    }

    .required:after {
      content: " *";
      color: $red;
    }

    .errorMessage {
      display: flex;
      justify-content: flex-start;
      color: $red;
    }

    .InputField {
      width: 90%;
      border-radius: 7px;
      border: 1px solid $borderGrey;
    }

    .submitButton {
      margin-left: 2%;
    }

    .d-flex {
      display: flex;
      direction: row;
      flex-wrap: wrap;
      margin-left: 3%;
      gap: 15px;
    }

    .form-check-label {
      padding: 3%;
      margin-top: -1%;
    }

    .formState {
      width: 90%;
    }
  }
}
