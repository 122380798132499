@import "./styles/index.scss";
@import './styles/layout/color.scss';
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

body {
  overflow-y: hidden;
  margin: 0 auto;
  font-family: 'Open Sans';
  @media (min-width: 1366px) {
    // width: fit-content;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $cancelWhite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $cyanBlue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cursor-pointer{
  cursor: pointer;
}
.labelPresentation{
  font-style: normal normal 600 18px/22px Open Sans;
  letter-spacing: 0px;
  color: $navy-blue;
  opacity: 1;
  top: 120px;
  left: 270px;
  width: 180px;
  height: 22px;
  font-size: 20px;
} 
a, a:hover, a:visited, a:active {
  // color: inherit;
  text-decoration: none;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-wrapper{
  width: 100%;
}