@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/mixins.scss";
.singleMandate {
  background: $GhostWhite;
  .create_manual_mandate {
    background: $DuskBlue;
    color: $white;
    padding: 10px;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
  }
  .single_mandate_form {
    height: 70vh;
    padding: 30px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    opacity: 1;
    overflow: auto;
  }
  .single_mandate_step {
    border-right: 1px solid $yellow;
  }

  @include device-md() {
    .single_mandate_step {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $yellow;
   
    }
    
  .activeStep {
    min-height: 40px;
  }
  .non-activeStep {
    min-height: 40px;
   }
   .single_mandate_form {
    display: flex;
  }
 
  }

  .activeStep {
    color: $DuskBlue;
    background: $darkBlueBG;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
  .non-activeStep {
    color: $dark-gray;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
}
