@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/mixins.scss";
@import "../../../styles/layout/common.scss";

.dynamicFormWrapper {
  .cardHeader {
    background-color: $navy-blue;
    color: $white;
    font-weight: 600;
  }

  .customButtonGreenInverted:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
  }

  .css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: #000000 !important;
    border-width: thin;
    padding: 1px 1px 1px 5px;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    max-height: 48px;
    overflow: hidden;
    padding: 6px;
    padding-left: 1px;

    ::-webkit-scrollbar {
      width: 2px;
    }

    /* Styling the scrollbar track */
    ::-webkit-scrollbar-track {
      background-color: #000000;
      /* Change to blue if that's your intention */
    }

    /* Styling the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background-color: red;
      border-radius: 5px;
    }
  }


  .css-t3ipsp-control {
    border-color: #5c5b5b;
    box-shadow: 0 0 0 1px #5c5b5b;
    height: 48px;
    overflow-y: auto !important;
    z-index: 10;
  }

  .css-3w2yfm-ValueContainer {
    border-color: #000000;
    box-shadow: #000000;
  }

  .css-1jqq78o-placeholder css-b62m3t-container {
    border-color: #000000;
    box-shadow: #000000;
  }

  .css-1jqq78o-placeholder {
    color: #000000;
    font-size: 13px;
    padding: 1px 1px 1px -30px;
  }

  .css-1u9des2-indicatorSeparator {
    width: 0px;
  }

  .css-tj5bde-Svg {
    color: #454545;
  }




  .customButtonGreenInverted {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $white !important;
    font-weight: 500;
    width: 160px;
    min-height: 40px;
  }

  .customButtonGreenInverted {
    border: 1px solid $Lemongreen;
  }

  .customButtonGreen:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid $Lemongreen;
  }

  .customButtonGreen {
    border: 1px solid $Lemongreen;
    color: $black;
    background: $Lemongreen !important;
    box-shadow: 0 !important;
    font-weight: 600;
    width: 160px;
    min-height: 40px;
  }

  .customButtonGreen {
    border: 1px solid $Lemongreen;
  }

  .inputDiv {
    p {
      color: $black;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .textInput {
    height: 48px;
  }

  .textInput:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $placeHolder;
  }

  .selectInput {
    height: 48px;
  }

  .bottomDiv {
    background: $white;
    border-radius: 8px;
    padding: 20px;
    height: 450px;
    overflow: auto;
  }

  .headerCheckBox {
    .form-check {
      padding: 8px 40px;
      background: $white;
      border-radius: 8px;
      margin: 2rem 0px;
    }

    input {
      height: 20px;
      width: 20px;
      border: 2px solid $navy-blue;
      border-radius: 0px !important;
      background: transparent;
    }

    label {
      font-size: 20px;
      font-weight: 500;
      color: $black;
      margin-left: 10px;
    }
  }

  .checkBoxForm {
    flex-flow: wrap;

    input {
      height: 20px;
      width: 20px;
      border: 2px solid $navy-blue;
      background: transparent;
    }

    input:checked {
      background-color: $navy-blue;
      border-color: $navy-blue;
    }

    input:focus {
      border-color: $navy-blue;
      box-shadow: none;
    }

    label {
      font-size: 16px;
      font-weight: 500;
      padding-top: 3px;
      margin-left: 8px;
    }
  }

  .dropDownPlaceholder {
    color: $placeHolder;
  }

  .customDatepicker {
    width: 100%;
    height: 48px;
    border: 1px solid $formBorderGrey;
    border-radius: 0.375rem;
    padding-left: 10px;
    outline: none;
    background: url("../../../Images/calendar.svg") no-repeat $white;
    background-size: 20px;
    background-position: 97% 50%;
  }

  .customDatepicker:focus {
    border: 1px solid $placeHolder;
  }

  .customTimepicker {
    width: 100%;
    height: 45px;
    border: 1px solid $formBorderGrey;
    border-radius: 0.375rem;
    padding-left: 10px;
    outline: none;
    padding-right: 11px;
    background-size: 20px;
    background-position: 97% 50%;
  }

  .customTimepicker:focus {
    border: 1px solid $placeHolder;
  }

  .textLabelRed {
    color: $red !important;
    font-size: 15px;
    font-weight: 600;
  }

  .dropWrapper {
    height: 80px;
    border: 2px dashed $borderGrey;
    color: $navy-blue;
    font-weight: 600;
    border-radius: 7px;
  }

  .uploadContent {
    word-break: break-word;
    max-width: 300px;
  }
}