
@import "../../../styles/layout/color.scss";

.bg {
  background-color: rgb(241, 241, 231);
  overflow-y: scroll;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.NoteText {
  font-size: 14px;
}



.UPIloader {
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}


.custombtn {
    display: block;
    width: 100%;
    max-width: 350px; 
    padding: 10px 20px;
    background-color: #65A765;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

.upiCollectBorder {
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 10px;
}


.upiDiv {
  border: 2px solid #dedede;
  border-radius: 5px; 
  margin-bottom: 10px;
}

.transactionDiv {
  padding: 20px;
}

.errorMessage {
  color: $red;
  font-size: 12px;
  padding: 4px 0px;
}

.payment-options {
  display: flex;
  gap: 10px;
}

.radio-container {
  flex-basis: 100%;

  margin-bottom: 10px;
}

.radio-box {
  display: flex;
  align-items: center;
  border: 1px solid $radio-box-color;
  border-radius: 5px;
  padding: 6px;
}

input[type="radio"] {
  margin-right: 10px;
}

@media screen and (min-width: 1200px) {
  .radio-container {
    //display: block;

    display: flex;

    flex-wrap: wrap;
  }

  .radio-box {
    min-width: 145px;
    flex-basis: auto;
  }

  .bg {
    height: 100vh;
  }
}

.form-control-lg {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: $white;
  color: $placeHolder;
  font-size: 12px;
}

.containerstyle {
  min-height: 470px;
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  border-radius: 5px;
}

.containerstyleUPI {
  min-height: 320px;
  margin-bottom: 5px;
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  border-radius: 5px;
}

.upiLabel {
  font-size: 20px;
}

.enachLabel {
  font-size: 20px;
  color: $orange;
}

.reasonLabel {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 17px;
  color: $cancelWhite;
}

.responsePage {
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 400px;
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  border-radius: 15px;
}

.linkExpired {
  justify-content: center;
  min-height: 400px;
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  border-radius: 15px;
  margin-top: 200px;
}

.containerstylePage {
  min-height: 400px;
  margin-top: 80px;
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  border-radius: 15px;
}

#second {
  position: absolute;
  top: 9px;
  right: 17px;
  font-size: 12px;
  color: $placeHolder;
}

label {
  margin-bottom: 2px;
  font: normal normal 600 13px/21px Open Sans;
}

.termstyle {
  font: normal normal 600 13px/21px Open Sans;
}

.note {
  font: normal normal 600 11px/21px Open Sans;
}

@media (max-width: 767px) {
  .note-custom {
    font: normal normal 600 1px/21px Open Sans;
  }
}

.upi-note {
  font: normal normal 700 12px/21px Open Sans;
}
.upi-note-ios {
  font: normal normal 600 10px/18px Open Sans;
}


#readiobtn {
  position: absolute;
  // top: 32px;
  // left: 25px;
  font-size: 12px;
  color: $placeHolder;

  .form-check-input {
    background-color: $white;
  }

  .form-check-input:checked[type="radio"] {
    background-color: $drop-zonetext;
  }
}

#debitbtn {
  position: absolute;
  top: 32px;
  // right: 0px;
  left: 220px;
  font-size: 12px;
  color: $placeHolder;

  .form-check-input {
    background-color: $white;
  }

  .form-check-input:checked[type="radio"] {
    background-color: $drop-zonetext;
  }
}

.radioborder {
  .form-control-lg {
    padding: 0.5rem 1rem;
    border-radius: 5px !important;
    font-size: 12px !important;
    background-color: $white;
  }

  .form-control {
    border: 1px $borderGrey solid;
  }
}

.form-check-input {
  background-color: $borderGrey;
}

.form-control:focus {
  color: $lightgrey;
  background-color: $borderGrey;
  border-color: $borderGrey !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem $borderGrey;
}

.checkboxlabel {
  font-size: 13px;
}

.btn-primary {
  width: 30%;
  height: 40px;
  border-radius: 2px;
}

.btn-secondary {
  width: 11%;
  height: 31px;
  border-radius: 2px;
}

.checkstyle {
  .form-check-input {
    background-color: $placeHolder;
  }
}

hr {
  border: 3px solid $yellow;
  width: 25%;
  margin-left: 37%;
}

.headnav {
  color: $white;
}

.main_navbar {
  height: 35px;
  background-color: $footergrey;
  color: $white;
}

.footer {
  position: relative;
  bottom: 0;
  height: 50px;
  width: 100%;
  color: white;
  background-color: $footergrey;
  padding: 10px;
}

.mynavbar {
  height: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $navbargrey;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: end;
}

.enachLabel {
  margin-top: 10px;
}

.logo {
  height: 40px;
}

.failLogo {
  width: 90px;
}

.secondimg {
  height: 32px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .containerstylet .container .row {
    padding-left: 0rem !important;
  }

  button {
    width: 50% !important;
  }

  .link-primary {
    margin-top: 5px;
  }
  .umrn-note {
    font: normal normal 600 11px/21px Open Sans;
  }
}
.payment-container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
}


.bhim-logo {
  max-width: 100px;
}
.info-row {
  background-color: #2d5794;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.info-box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.or-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.or-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #2d5794;
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.qr-code {
  width: 200px;
  display: block;
  margin: 0 auto; 
}
.custom-button {
   padding: 0.5rem 1rem; 
   width: auto;
   height: 40px;
   font-size: smaller;
}
.qr-codeImg {
  width: 180px;
  display: block;
  margin: 0 auto; 
}
.upiImg {
  width: 60px;
  display: block;
  margin: 0 auto; 
}
.qr-borderImg{
  border: 1px solid white !important; 
  border-radius: 10px !important;
}
.qr-border{
  border: 15px solid white; 
  border-radius: 10px;
}

.upi-section {
  background-color: #2d5794;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
}

.qr-section {
  background-color: #ffffffb3;
  padding: 15px;
  border-radius: 5px;
}

.mt-5 {
  margin-top: 3rem;
}

.text-center img {
  max-width: 200px;
}

a:hover {
  text-decoration: underline;
}

.blue-background {
  background-color: #f1faff;
  padding: 15px;
  border-radius: 8px;
  margin: 0 auto;
}
.left-side {
  position: relative; 
  padding-left: 20px;
  padding-top: 20px;
}

.left-side::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  bottom: 0;
  width: 2px;
  background-color: #c3c4c5;
}

.blue-bg {
  background-color: #f1faff;
}
.position-relative .or-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $navy-blue;
  border-radius: 50%;
  background-color: $navy-blue;
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
}

.or-left {
  right: -15px; 
}

.or-right {
  left: -15px;
}

.dynamicFormWrapper .customButtonGreen {
  color: $black;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.blue-bg{
  background-color: #f1faff;
}

.or-wrapper-mobile {
  display: none;
}
.or-circle.or-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
}
.check-margin{
  margin-left: 3.5rem;
}
@media (max-width: 767px) {
  .col-md-12,
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fs-sm{
    font-size:  13px; 
  }
  .or-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
  }
  .jc-center{
    justify-content: center !important;
  }
  .or-circle {
    margin: 0 auto;
  }
  .position-relative .or-circle{
    top:47%
  }
  .umrn {
    word-break: break-all !important;
    font-size: smaller;
  }
  .blue-background {
      padding: 15px; 
      max-width: calc(100% - 3rem); 
      margin: 0 auto; 
    
  }
  .or-circle.or-right {
    left: -10px;
    right: unset; 
    display: none;
  }
  .check-margin{
    margin-left: 2rem;
  }
  .check-margin-custom{
    margin-left: 0rem;
  }
}
.checkboxlabel {
  margin-left: 2.5rem;
}.labelColor{
  color: black;
}
.termstyle.note {
  word-wrap: break-word;
  // padding: 0px; 
}
.umrn {
  display: block;
}
