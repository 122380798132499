@import "../../../styles/layout/common.scss";
.paymentReport {
  &_paymentReportTxt {
    color: $DuskBlue;
    font-size: 16px;
  }
  &_inputContainer {
    box-shadow: 1px 1px $grey;
    background-color: white;
    border-radius: 5px;
    border: solid $grey 1px;
    // width: 95%;
    // padding-right: 4rem !important;
    .dynamicFormWrapper .customDatepicker {
      width: 100%;
      height: 41px;
      border: 1px solid #ced4da;
      border-radius: 0.375rem;
      padding-left: 10px;
      outline: none;
      margin-top: 5px;
    }
    .dynamicFormWrapper .selectInput {
      height: 41px !important;
      width: 100%;
      color: $black;
      margin-top: 5px;
    }
  }
  &_datetxt {
    font-size: 12px !important;
  }
}
.custom-table {
  table {
    thead {
      th:first-child {
        padding-left: 15px !important;
        padding-right: 15px !important;
        min-width: max-content !important;
      }
    }
    .idData {
      min-width: max-content !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}
