@import "../../../styles/layout/color.scss";
@import "../../../styles/layout/mixins.scss";
$submitBtn: selector-nest(".singleVerifySubmit", "&_submitBtn");

#{$submitBtn} {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
  max-height: 2.6rem;
}
.singleVerify {
  background: $GhostWhite;
  padding-bottom: 0 !important;
  .create_manual_mandate {
    background: $DuskBlue;
    color: $white;
    padding: 10px;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
  }
  .right-side-div {
    overflow: auto;
    max-height: 64vh;
    &::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: $GhostWhite;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $navy-blue;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
      background-color: $GhostWhite;
      border-radius: 10px;
    }
  }

  &_bulkMandateBtn {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
    max-height: 2.6rem;
    @include device-md {
      margin-bottom: 1rem;
    }
  }
  .single_verify_form {
    min-height: 70vh;
    min-height: 40px;
    @include device-md {
      margin-bottom: 2rem;
    }
    padding: 30px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    opacity: 1;
  }
  .single_verify_step {
    border-right: 1px solid $yellow;
  }
  .activeStep {
    color: $DuskBlue;
    background: $blueBG;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
  .non-activeStep {
    color: $formBorderGrey;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
}
