@import "../../../styles/layout/color.scss";

.btn{
    padding: 0px;
    border: none;
}
.downloadBtn{
    height: 32px;
}

.demergeSysLabel {
    font-weight: bold;
}
.colorBlueFont {
    color: $tableBoldFont !important;
}
.demergeSystemCountWrapper{
    width: 100vw;
}