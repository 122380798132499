@import "../../../styles/layout/common.scss";
.disbursementTable {
  &_downloadBtn
  {
    float: right;
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
  &_disbursementTableTxt {
    color: $DuskBlue;
    font-size: 16px;
  }
  &_inputContainer {
    box-shadow: 1px 1px $grey;
    background-color: $white;
    border-radius: 5px;
    border: solid $grey 1px;
    width: 95%;
    padding-right: 4rem !important;
    .dynamicFormWrapper .customDatepicker {
      margin-top: 5px;
      width: 100%;
      height: 41px;
      border: 1px solid $formBorderGrey;
      border-radius: 0.375rem;
      padding-left: 10px;
      outline: none;
  }
  }
  &_datetxt {
    font-size: 12px !important;
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.step-marker {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 {
  margin: 0;
}

.progress {
  width: 2px;
  height: 40px;
  margin-left: 20px;
}

.step-connector {
  // border: 2px solid red;
  height: 50px;
}

.disabled-tab {
  background-color: #d3d3d3; /* Light Gray */
}
