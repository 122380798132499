@import "../../../../styles/layout/color.scss";

#EnachVendor {
    .alignHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .blueFont {
        color: $DuskBlue;
    }

    .setMargin {
        margin: 4% 2%;
    }

    .setTableMargin {
        margin: 0% 2%;
    }
    .icon {
        padding: 4%;
        cursor: pointer;
      }
      .delete_icon {
        padding: 4%;
      }

      .exportBtn {
        background-color: $white;
        border-radius: 6px;
        border: 1px solid $tableBoldFont;
        color: $black;
        text-transform: capitalize;
        font-size: 14px;
        padding: 6px 35px;
        cursor: pointer;
    
        &:hover {
          background-color: $white;
          border-radius: 6px;
          border: 1px solid $tableBoldFont;
          color: $black;
          text-transform: capitalize;
          font-size: 14px;
          padding: 6px 35px;
          cursor: pointer;
    
        }
      }
      
      .exportBtn.disabled {
        opacity: 0.5; 
        cursor: not-allowed; 
      }
}