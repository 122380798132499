@import '../../styles/layout/color.scss';
.tooltip {
  opacity: 1 !important;
}
.tooltip-inner {
  background-color: $white !important;
  color: $navy-blue !important;
  border: solid $navy-blue 2px;
  font-weight: bold;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: $navy-blue !important;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
}
