@import '../../../../../styles/layout/color.scss';

#ReceiptType{
    .alignHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .blueFont {
        color: $DuskBlue;
      }
    
      .setMargin {
        margin: 4% 2%;
      }
    
      .setTableMargin {
        margin: 0% 2%;
      }
      .icon {
        padding:2%;
        cursor: pointer;
      }
      .delete_icon {
        padding: 2%;
      }
   
}