@import "../../../styles/layout/color.scss";
.MergeTable {
  .batch_id {
    font-weight: bold;
    color: $tableBoldFont;
  }
  .download {
    .form-select:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none;
      border: 1px solid $Lemongreen;
    }
    .form-select {
      border: 1px solid $Lemongreen;
      width: 150px;
    }
    .select-menu {
      border: 1px solid $Lemongreen;
    }
  }
}
.btn {
  padding: 0px;
  border: none;
}
.primaryBtn {
  height: 32px;
}
.cls-btn {
  border: 3px solid $navy-blue;
  border-radius: 100%;
}
.cls-btn:hover {
  background-color:$white;
}
.merge {
  &-searchBox {
    
      padding:0.2rem;
      height: 32px;
      font: normal normal normal 12px/17px Open Sans;
      letter-spacing: 0px;
      color: $black;
      border: 1px solid $gray;
      border-radius: 3px;
    
    
  }
  &-dropdown
  {
    border: 1px solid $dark-gray;
    border-radius: 3px;
    padding: 0.2rem;
    min-width: 157px;
    height:2rem;
    font: normal normal 600 10px/14px Open Sans;
  }
}
