@import "../../../../styles/layout/color.scss";
.reversaldate {
  height: 43px !important;
  width: 400px !important;
}

.bankList {
  &_banks {
    margin-bottom: 0.5rem;
    border: solid 1px $grey;
    width: 95%;
  }
  &_bank-txt {
    font-size: 12px !important;
  }
  &_bankWrapper {
    max-height: 15rem;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $tableBorderGrey;
      background-color: $GhostWhite;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: $GhostWhite;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $navy-blue;
    }
  }

  p {
    color: $black;
    font-size: 12px;
    font-weight: 600;
    width: 10rem !important;
    word-wrap: break-word;
  }
  .customDatepicker {
    width: 12rem !important;
    float: right;
  }
}
// .datepicker {
//     border: 1px solid #dcdcdc;
//     width: 430px;
//     border-radius: 6px;
// }
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  margin-top: 3px;
}
.inputDiv {
  p {
    color: $black;
    font-size: 15px;
    font-weight: 600;
  }
}

.inputDivDemerge {
  p {
    color: $black;
    font-size: 15px;
    font-weight: 500;
    width: 500px;
  }
}
