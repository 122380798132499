@import '../../../styles/layout/color.scss';
.mandate_details {
  .errorMessage {
    color: red;
    font-size: 12px;
    padding: 4px 0px;
  }
  .field_name {
    font-weight: bold;
    font-size: 15px;
  }
  &-submitBtn {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
  &-backBtn {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: 20px;
  }
}
