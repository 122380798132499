@import '../../../../styles/layout/color.scss';

#BancsSponsor{
    .icon {
        padding:5%;
        cursor: pointer;
      }
      .delete_icon {
        padding: 5%;
      }
      .alignHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .blueFont {
        color: $DuskBlue;
      }
    
      .setMargin {
        margin: 4% 2%;
      }
    
      .setTableMargin {
        margin: 0% 2%;
      }
}