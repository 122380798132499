@import "../../../styles/layout/color.scss";
.dropdownWrapper {
  .dropdown-menu {
    max-height: 13rem !important;
    overflow-y: auto;
    overflow-x: scroll;
    max-width: 100%;
  }
  .dropdown-toggle {
    height: 38px !important;
    width: 100% !important;
    background: $white 0% 0% no-repeat padding-box !important;
    border-color: $grey !important;
    border-radius: 2px;
    font-size: 12px;
    color: $black !important;
    border: solid $black 1px !important;
    white-space: nowrap;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
    outline: none !important;
  }

  .dropdownInputBox,
  .dropdownInputBox:focus-visible {
    border: none;
    outline: none;
    &::placeholder {
      color: black;
      font-weight: 400;
    }
  }
  .dropdownButton {
    border: 2px solid $grey !important;
  }

  // .dropdown-toggle::after {
  //   background-image: url("../../../Images/downArrow.svg") !important;
  //   background-size: contain;
  // }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    color: $white !important;
    background-color: $blue;
    height: 1.2rem;
    display: flex;
    align-items: center;
    padding: 2px;
  }
  .dropdown-item {
    height: 1.2rem;
    display: flex;
    align-items: center;
    padding: 2px;
  }
  .spanText {
    color: $black !important;
    margin-left: 5px;
    font-size: 12px;
  }
  .dropdown-toggle::after {
    content: "";
    display: block !important;
    border-bottom: 2px solid $black !important;
    border-right: 2px solid $black !important;
    transform: rotate(45deg) !important;
    height: 6px !important;
    width: 6px !important;
    position: absolute !important;
    cursor: pointer !important;
    right: 3% !important;
    top: 37% !important;
    border-top: 0 !important;
  }
  a,
  a:hover,
  a:visited,
  a:active {
    span {
      color: $white !important;
    }
  }
  .dropdown-menu.show {
    display: block;
    width: 100%;
    overflow: auto;
  }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
}
