@import "../../../styles/layout/mixins.scss";
@import "../../../styles/layout/color.scss";

.left-div-header-mandate {
  width: calc(100% - 65%);
}
.right-div-header-mandate {
  width: 65%;
}

.createMandateBtn
{
  margin-top: 0.35rem;
  cursor: pointer;
}

@include customWidthMedia(0px, 764px) {
  .left-div-header-mandate {
    width: 100%;
  }
  .right-div-header-mandate {
    width: 100%;
  }
}

.createSingleMandateBtn {
  background-color: $Lemongreen !important;
  font-size: 14px !important;
  font-weight: 600;
  color: $black;
  border: 0;
  border-radius: 5px !important;
  padding: 6px 1.4rem;
  border-radius: 0;
  width: max-content !important;
}
