@import "../../../styles/layout/color.scss";
.RegularPayment {
  &_max-widthTable {
    // max-width: 54rem;
  }
  &_checkbox{
    background-color: white !important;
  }
  &_tableAlignment {
    width: 100%;
    &_checkbox {
      height: 19px !important;
      width: 19px !important;
    }

    .custom-table {
      margin-top: 0 !important;

      table {
        thead {
          height: 3.45rem;

          th {
            min-width: 10rem;
          }
          th:first-child {
            padding-left: 15px;
            padding-right: 15px;
            min-width: max-content !important;
          }
          th:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
            min-width: max-content !important;
          }
        }
        td {
          min-width: 160px;
          // min-width: max-content !important;
        }
      }
    }
    .custom-table table {
      overflow: hidden;
      border-radius: 0px 10px 0rem 0rem !important;
      margin-bottom: 0px;
    }
    .id {
      min-width: max-content !important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .srNo, .beneNameScore {
      min-width: max-content !important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .branch,
    .productDesc {
      min-width: 180px !important;
    }
    .journalNo {
      min-width: 240px !important;
    }
    .txnIMBSName,
    .Bene_Bank_Acct,
    .disAmt {
      min-width: 204px !important;
    }
    .checkBoxForm {
      // min-width: max-content !important
    }
  }
  &_regularPaymentTxt {
    color: $navy-blue;
  }
}
.custom-switch {
  font-size: 21px;
  width: 100%;

//   .ui.toggle.checkbox .box:after,
//   .ui.toggle.checkbox label:after {
//     background-color:$Lemongreen !important;
//   }

//   .ui.toggle.checkbox input:checked ~ .box:before,
//   .ui.toggle.checkbox input:checked ~ label:before {
//     background-color: #ffe7c8 !important;
//   }
// }
// .custom-switch .form-check-input {
//   background-color: #ffff; /* Change the background color */
//   border-color: #ffff; /* Change the border color */
}

.custom-switch .form-check-input:checked {
  background-color: #004e06;
  border-color: $navy-blue; 
}
.custom-border {
  border-right: 1px solid black;
}
.custom-switch .form-check-input:not(:checked) {
  background-color: white !important;
  border-color: #88b3e6 !important;
}


.modalBtnCstm{
  background: $navy-blue;
}
.checkBoxForm {
  input {
    height: 20px;
    width: 20px;
    accent-color: $tableBoldFont;
    background: $tableBoldFont;
  }
}

.me-2 {
  margin-right: 3.5rem !important;
}

.dropdown-border {
  border: 1px solid #040303;
  border-radius: 4px;
  padding: 5px;
}

.no-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.25rem;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}
.selected-option {
  width: 100px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.css-1fdsijx-ValueContainer{
  width: 150px !important;
}

.form-select{
  -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: #000000 !important;
    border-width: thin;
    padding: 1px 1px 1px 5px;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    max-height: 48px;
    overflow: hidden;
    padding: 6px;
    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;


    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    
    /* Styling the scrollbar track */
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    
    /* Styling the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 5px;
    }
    
    // ::-webkit-scrollbar {
    //   width: 2px;
    // }
    
    /* Styling the scrollbar track */
    // ::-webkit-scrollbar-track {
    //   background-color: #000000; /* Change to blue if that's your intention */
    // }
    
    /* Styling the scrollbar thumb */
    // ::-webkit-scrollbar-thumb {
    //   background-color: red;
    //   border-radius: 5px;
    // }

}

 
  .p-multiselect-header {
    display: inline-flexbox;
    align-items: center;
    justify-content: space-between;
  }
  .p-multiselect-items-wrapper{
    overflow: scroll;
    align-items: center;
  }
  .p-icon{
    display: none;
  }

.selectInput {
  min-width: 148px !important;
}

.p-checkbox-input{
  display: inline-block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -4.5em;
  margin-top: 3px;
}

.regular-payment-wrapper-row {
  height: 100%;
  .Payment_DisbursmentCaseWrapper {
    height: 100%;
    background: $white;
  }
  .RegularPayment_max-widthTable {
    border: 1px solid $grey;
    border-left: 0px;
    background: $white;
    height: 100%;
    .spinnerDiv{
      margin-top: 250px;
    }
  }
}

.custom-margin-left .form-check-input {
  margin-left: 0 !important;
}