@import '../../../styles/layout/color.scss';

.main-container {
    width: 95%;
   
    .registration-dropdown {
        border: 1px solid $gray;
        border-radius: 5px;
        font: normal normal normal 14px/20px Open Sans;
        letter-spacing: 0px;
        color: $dark-gray;
    }

    .subtitle {
        font: normal normal 600 12px/21px Open Sans;
        letter-spacing: 0px;
        color: $black;
        opacity: 1;
    }

    .register-datepicker {
        background: $white;
        padding: 2px;
        height: 38px !important;
        border-radius: 5px;
    }

    .datepicker {
        border-radius: 5px;
        min-width: 470px;
    }

    .form-select:focus {
        border-color: $gray;
        outline: 0;
        box-shadow: none;
    }

    .red-star { 
        color: $red; 
    }

    .required:after {
        content: " *";
        color: $red; 
    }

    .errorMessage { 
        display: flex; 
        justify-content: flex-start; 
        color: $red; 
        margin-top: 8px;
    }

    .loaderDiv {
        width: 99%;
    }
}

.dynamicFormWrapper .selectInput {
    height: 48px;
    width: 100% !important;
    color: $black;
}

.dynamicFormWrapper .customButtonGreen {
    color: $black;
}

.customDatepicker, .customTimePicker {
    margin-top: 0.25rem;
    width: 100%;
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
    padding-left: 10px;
    outline: none;
}

.dynamicFormWrapper .customTimePicker {
  input {
    height: 48px;
    width: 100% !important;
    
    // padding: 0.375rem 0.75rem;
    // font-size: 2rem;
    // line-height: 1.5;
    // color: #495057;
    // background-color: #fff;
    // background-clip: padding-box;
    // // border: 1px solid  $gray;
    // // border-radius: 0.25rem;
    // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .react-select-container {
    .react-select__control {
      min-height: 38px;
      height: 38px;
      border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
    padding-left: 10px;
    outline: none;
      &:hover {
        border-color: #888;
      }
    }

    .react-select__value-container {
      padding: 0 8px;
    }

    .react-select__single-value {
      font-size: 1rem;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      padding: 8px;
    }

    .react-select__menu {
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    .react-select__option {
      padding: 8px;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .react-select__option--is-selected {
      background-color: #e0e0e0;
    }

    .react-select__option--is-focused {
      background-color: #f8f8f8;
    }
  }
}

