@import "../../../styles/layout/color.scss";

.billuploadStatus {
  &_uploadBtn {
    background-color: $Lemongreen !important;
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    border: 0;
    border-radius: 5px !important;
    padding: 6px 1.4rem;
    border-radius: 0;
    width: max-content !important;
    height: 100%;
  }
  .rejected_count {
    font-weight: bold;
    color: $tableBoldFont;
  }
  .user_name {
    width: 200px;
  }
  .upload_status {
    width: 180px;
  }
  .loaderDiv {
    width: 99%;
  }
}
.btn {
  padding: 0px;
  border: none;
}
.primaryBtn {
  height: 32px;
}
.billupload-drpodown {
  border: 1px solid $dark-gray;
  border-radius: 3px;
  padding: 0.2rem;
  min-width: 157px;
  height: 2rem;
  font: normal normal 600 10px/14px Open Sans;
}
.billupload-inputfield {
  height: 32px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 3px;
}
.billuploadStatus {
  .date_time {
    min-width: 210px;
  }
  .user_name {
    min-width: 254px;
  }
  .upload_status {
    min-width: 156px;
  }
  .total_count {
    min-width: 125px;
  }
}
