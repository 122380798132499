@import '../../styles/layout/color.scss';

#assignRole {
  width: 100%;
  height: 100%;

  .normal-createrole-text {
    margin-left: 32px;
  }

  .Userlabel {
    color: $DuskBlue;

    cursor: pointer;
  }

  .labelcolor {
    color: $black;
  }

  .pagebox {
    background-color: $white;
    width: 95%;
    margin-left: 32px;
    box-shadow: 0px 0px 5px $black;
    border-radius: 0px 0px 5px 5px;
  }

  .select-container {
    display: flex;
    flex-direction: column;
    // width: 40%;
    margin-bottom: 2%;
  }

  .data-container {
    margin-left: 36px;
    margin-top: 35px;
  }

  .usertext {
    margin-top: 36px;
  }

  .select {
    height: 37px !important;
  }

  .required:after {
    content: " *";
    color: $red;
  }

  .errorMessage {
    display: flex;
    justify-content: flex-start;
    color: $red;
  }

  .cancel_btn,
  .submitButton {
    align-self: center;
    color: $cancelWhite;
    margin-bottom: 40px;
  }

  .submitButton {
    margin-left: 32px;
  }

  .buttonSets {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .buttonMargin {
    margin: 0% 0% 0% 10%;
  }
}