@import '../../../styles/layout/color.scss';
.containerstyle {
    min-height: 200px;
    height: auto;
    // background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
  }

  .cancel{ 
    font-size: 14px !important; 
    padding: 6px 50px !important; 
  } 
  .Download{ 
    font-size: 14px !important; 
    padding: 6px 35px !important; 
  }
  .label{
      font: normal normal 600 12px/21px Open Sans;
      letter-spacing: 0px;
  }

  .reversalStatus {
    &_reversalStatusTxt {
      color: $DuskBlue;
      font-size: 16px !important;
    }
    &_inputContainer {
      box-shadow: 1px 1px $grey;
      background-color: white;
      border-radius: 5px;
      border: solid $grey 1px;
      width: 95%;
      padding-right: 4rem !important;
      .dynamicFormWrapper .customDatepicker {
        width: 100%;
        height: 41px;
        border: 1px solid $formBorderGrey;
        border-radius: 0.375rem;
        padding-left: 10px;
        outline: none;
        margin-top: 5px;
      }
      .dynamicFormWrapper .selectInput {
        height: 41px !important;
        width: 100%;
        color: $black;
        margin-top: 5px;
      }
    }
    &_datetxt {
      font-size: 12px !important;
    }
  }

  .custom-table {

    table {
      thead {
        th:first-child {
          padding-left: 15px !important;
          padding-right: 15px !important;
          min-width: max-content !important;
        }
      }
      .idData {
        min-width: max-content !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }