$white: #ffffff;

$Lemongreen: #dfdf23;
$cyanBlue:#61dafb;
$navy-blue: #2d5794;
$btnBlue:#3491ef2d;

$dark-green: #5cb85c;

$radio-box-color:#ccc;
$red: #ff0000;

$black: #000000;

$grey: #dcdcdc;

$GhostWhite: #f8fbff;

$DuskBlue: #2d5794;
$black: #000000;
$light-Blue: #f8fbff;
$tableBoldFont: #005899;
$tableBorderGrey: #0000001a;
$grey: #dcdcdc;
$tableBorderGrey : #0000001A;
$customtableBorder : #dee2e6;
$greycol:grey;

$gray :#DCDCDC;

$dark-gray:#A7A7A7;
$placeHolder: #69757d;
$formBorderGrey: #ced4da;
$drop-zonetext:#0067FC;
$grey: #dcdcdc;
$placeHolder: #69757d;
$formBorderGrey: #ced4da;
$borderGrey: #DCDCDC;
$blueBG:#01192b1a;
$darkBlueBG:#0058991A;
$cancelWhite:#333333;
$approveBlue:#337ab7;
$orange: #E47210;
$lightgrey:#212529;
$yellow:yellow;
$footergrey:#323232;
$navbargrey:#00000029;
$exportBlue: #4a7abf;
$blue:blue;
$togglebg: #BEBEBE;
$togglebg2: #9B9B9B;
$togglebg3: #4B4B4B;
$togglebg4: #717171;