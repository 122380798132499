.small_f {
    font-size: 10px;
}

.small_f_bold {
    font-size: 10px;
    font-weight: bold;
}

.smallest_f {
    font-size: 9px;
}

.smallest_f_bold {
    font-size: 9px;
    font-weight: bold;
}

.medium_f {
    font-size: 12px;
}

.medium_f_bold {
    font-size: 0.8rem;
    font-weight: bold;
}

.large_f {
    font-size: 18px;
    font-weight: normal;
}

.large_f_bold {
    font-size: 1rem;
    font-weight: bold;
}

.larger_f {
    font-size: 1.1rem;
}

.larger_f_bold {
    font-size: 1.2rem;
    font-weight: bold;
}

.larger_f_bold_title {
    font-size: 18px;
    font-weight: bolder;
}

.directionColumn{
    display: flex;
    flex-direction: column;
}

.directionRow {
    display: flex;
    flex-direction: row;
}

 .required:after {
     content: " *";
     color: red;
 }